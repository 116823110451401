import React, { MouseEventHandler, useEffect, useMemo } from "react";
import classnames from "classnames";
import dynamic from "next/dynamic";
import Link from "next/link";

import { Action, Category } from "constants/events";
import { CurrentStatus, Dispensary } from "custom-types/Dispensary";
import { ActiveDeal } from "custom-types/Store";
import useDomainCountryCode from "hooks/useDomainCountryCode";
import { useEventTracker } from "hooks/useEventTracker";
import { getDistanceCustomRounding } from "utils/distance";
import { getMenuTypeLabel } from "utils/menuTypeUtils";

import Button from "components/botanic/Button";
import StarRating from "components/botanic/StarRating/StarRating";
import AwardBadgeIcon from "components/Icons/award_badge.svg";
import Image from "components/Image";
import { Sizes } from "components/Image/Image";
import OfferLabel from "components/OfferLabel";

import CardRibbon from "./CardRibbon";
import DefaultPickupLabel from "./DefaultPickupLabel";

type SVGProps = {
  height: string;
  width: string;
  className?: string;
};
const ScooterIcon = dynamic<SVGProps>(
  () => import("components/Icons/scooter.svg"),
);
const StorefrontIcon = dynamic<SVGProps>(
  () => import("components/Icons/storefront.svg"),
);
const ScheduleStatus = dynamic(
  () => import("components/botanic/ScheduleStatus"),
);
const KeyDeliveryVariables = dynamic(
  () => import("components/botanic/KeyDeliveryVariables"),
);

//https://github.com/Leafly-com/consumer-api/blob/develop/app/models/finder_dispensary.rb#L169
export const FEATURE_TIERS = {
  basic: 400,
  info: 900,
  platinum: 100,
  premium: 100,
  pro: 200,
  standard: 300,
  starter: 600,
};

export type DispensaryCardProps = Pick<Dispensary, "name" | "slug"> &
  Partial<
    Pick<
      Dispensary,
      | "deliveryServiceAreaRanges"
      | "distanceMi"
      | "isNewDispensary"
      | "pickupEnabled"
      | "preorderConfigured"
      | "retailType"
      | "timeZone"
    >
  > & {
    /**
     * Element that would display at the bottom of the card, replaces the default CTA button
     */
    accessoryTray?: JSX.Element;
    className?: string;
    /**
     * Minimizes certain parts of the card height, e.g. for Finder map view carousel
     */
    compact?: boolean;
    /**
     * Applied to the Image component. See Image component for explanation on Sizes array.
     */
    coverPhotoSizes?: Sizes;
    /**
     * Optional href to use in the default CTA button
     * @default undefined
     */
    ctaHref?: string;
    /**
     * Optional function to call when the default CTA button is clicked, trackEvent etc.
     */
    ctaOnClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
    /**
     * Used to display current store open/close status
     */
    currentStatus?: CurrentStatus | null;
    /**
     * Deal to show on select platinum cards
     */
    deal?: {
      active: boolean;
      discountLabel: string;
      isMedical?: boolean;
      isRecreational?: boolean;
      title: string;
      kind?: string | null;
      externalKind?: string | null;
    };
    deliveryEnabled?: boolean;
    /**
     * Toggle whether the image should lazyload
     * @default false
     */
    disableLazyLoad?: boolean;
    dispensaryId: number;
    featureTier?: number;
    hideCTA?: boolean;
    hideDeliveryFee?: boolean;
    hideOnMobile?: boolean;
    /**
     * URL override the dispensary card should link to
     * @default "/dispensary-info/:slug"
     */
    href?: string;
    inStoreCartEnabled?: boolean;
    isAd?: boolean;
    isFeaturedDispensariesSection?: boolean;
    logoUrl?: string | null;
    mostRecentActiveDeal?: ActiveDeal;
    /**
     * Click handler for the card
     */
    onClick?: MouseEventHandler<HTMLAnchorElement>;
    /**
     * Photo URL to show on platinum cards
     */
    photoUrl?: string;
    /**
     * Boolean value to set a min-height style on some contents of
     * the card to keep them inline even if missing
     */
    preserveHeight?: boolean;
    preload?: boolean;
    rating?: number;
    /**
     * Render prop for showing pickup readiness label
     */
    renderReadinessLabel?: () => React.ReactNode;
    reviewCount?: number;
    scheduleType?: "pickup" | "delivery" | "store";
    /**
     * If including deal prop, set this to true to maintain
     * height of deal section of card
     */
    showDeal?: boolean;
    trackImpression?: () => void;
    tags?: string[];
    flags?: string[];
    /**
     * Uses old logic for displaying distances
     */
    useLegacyFulfillmentText?: boolean;
    /**
     * Which variant of the card to display, takes priority over featureTier value
     * Card will default to standard if neither prop is provided
     */
    variant?: keyof typeof FEATURE_TIERS;
  };

const DispensaryCard: React.FC<DispensaryCardProps> = ({
  accessoryTray,
  className,
  compact = false,
  coverPhotoSizes = [312],
  ctaHref,
  ctaOnClick,
  currentStatus,
  deal,
  deliveryEnabled = false,
  deliveryServiceAreaRanges,
  disableLazyLoad = false,
  dispensaryId,
  distanceMi,
  featureTier,
  hideCTA = false,
  hideDeliveryFee = false,
  hideOnMobile = false,
  href,
  inStoreCartEnabled = false,
  isAd = false,
  isFeaturedDispensariesSection = false,
  isNewDispensary,
  logoUrl,
  name,
  onClick,
  photoUrl,
  pickupEnabled,
  preorderConfigured,
  preload,
  preserveHeight = false,
  rating,
  renderReadinessLabel,
  retailType,
  reviewCount,
  scheduleType = "store",
  slug,
  showDeal,
  tags = [],
  flags = [],
  timeZone,
  trackImpression,
  useLegacyFulfillmentText = false,
  variant,
  ...others
}) => {
  // Either use the provided variant or the dispensary's feature tier value
  const countryCode = useDomainCountryCode();
  const tierValue = (variant && FEATURE_TIERS[variant]) ?? featureTier ?? 400;
  const dispensaryHref = useMemo(() => {
    if (href) {
      return href;
    }
    if (countryCode === "CA") {
      return `/cannabis-store/${slug}`;
    }
    if (retailType === "clinic") {
      return `/doctors/${slug}`;
    }
    if (retailType === "cbd-store") {
      return `/cbd-store/${slug}`;
    }
    return `/dispensary-info/${slug}`;
  }, [href, countryCode, slug, retailType]);
  const dispensaryMenuHref = ctaHref || dispensaryHref + "/menu";

  const { publishEvent } = useEventTracker();

  useEffect(() => {
    if (trackImpression) {
      trackImpression();
    }
  });

  useEffect(() => {
    if (isNewDispensary) {
      publishEvent({
        action: Action.impression,
        category: Category.finder,
        dispensaryId,
        label: "new label",
      });
    }
  }, [isNewDispensary]);

  // Determines if we explicitly reserve space for two lines of a dispensary's
  // name and delivery/pickup tags
  const shouldSetMinHeight = preserveHeight && tierValue <= 900;

  let logoSize = 48;

  // Use a larger logo if no photoUrl is present on platinum cards
  if (tierValue && tierValue <= 100 && !photoUrl) {
    logoSize = 128;
  } else if (compact) {
    logoSize = 56;
  }

  const mergedTagsAndFlags = useMemo(() => [...tags, ...flags], [tags, flags]);

  const dispensaryTypeText = getMenuTypeLabel({
    isCA: countryCode === "CA",
    tags: mergedTagsAndFlags as Dispensary["tags"],
  });
  // Verify titleAs is a valid h tag before using it
  const {
    active: dealActive,
    discountLabel,
    kind,
    externalKind,
    isMedical,
    isRecreational,
    title: dealTitle = "",
  } = deal || {};
  const offerTypes = ["bogo", "bundle"];
  const dealIsOffer =
    offerTypes.includes(externalKind || "") || offerTypes.includes(kind || "");

  let dealHref =
    (countryCode === "CA"
      ? `/cannabis-store/${slug}/menu`
      : `/dispensary-info/${slug}/menu`) +
    `?deal_title[]=${encodeURIComponent(dealTitle)}`;
  // Query params for showing medical or recreational menus for dual-menu dispensaries
  if (isMedical && !isRecreational) {
    dealHref += "&menu_type=Med";
  }
  if (isRecreational && !isMedical) {
    dealHref += "&menu_type=Rec";
  }

  //Leafly or third-party delivery
  const hasAnyDelivery =
    deliveryEnabled || mergedTagsAndFlags.includes("delivery");
  const deliveryOnly = hasAnyDelivery && !pickupEnabled;

  let distance = null;
  if (distanceMi) {
    distance = getDistanceCustomRounding(
      distanceMi,
      countryCode,
      !useLegacyFulfillmentText,
    );
  }

  let fulfillmentText;

  if (useLegacyFulfillmentText) {
    if (
      distance &&
      !(deliveryOnly && mergedTagsAndFlags.includes("storefront"))
    ) {
      fulfillmentText = `${distance} away`;
    }
  } else if (deliveryOnly) {
    fulfillmentText = "delivery only";
  } else if (distance && hasAnyDelivery) {
    fulfillmentText = `${distance} away · delivery`;
  } else if (distance) {
    fulfillmentText = `${distance} away`;
  }

  let ctaText;
  if (inStoreCartEnabled) {
    ctaText = "order pickup";
  }
  if (pickupEnabled && !deliveryEnabled) {
    ctaText = "order pickup";
  }
  if (!pickupEnabled && deliveryEnabled) {
    ctaText = "order delivery";
  }
  if (pickupEnabled && deliveryEnabled) {
    ctaText = "order delivery or pickup";
  }

  // Show a CTA at the bottom if accessoryTray provided or if delivery/pickup/in-store cart enabled
  const showCTA = tierValue <= 400 && (accessoryTray || (!hideCTA && ctaText));

  return (
    <div
      className={classnames(
        "flex flex-col items-start justify-between relative h-full w-full rounded overflow-hidden bg-white shadow-low",
        { "p-lg": !compact, "p-md": compact },
        className,
      )}
      {...others}
    >
      <div className={classnames("w-full", { flex: compact })}>
        {compact && logoUrl && (
          <div className="mr-2" style={{ minWidth: logoSize, width: logoSize }}>
            <Image
              src={logoUrl}
              sizes={[logoSize]}
              alt={`${name}-logo`}
              ratio="square"
              disableLazyLoad={disableLazyLoad}
              preload={preload}
            />
          </div>
        )}
        <Link
          onClick={(e) => onClick?.(e)}
          href={
            isFeaturedDispensariesSection ? dispensaryMenuHref : dispensaryHref
          }
          data-testid="dispensary-card__link"
          className={classnames({ "w-[81%]": compact })}
        >
          {/* Images and med/rec label */}
          {tierValue <= 400 && (
            <div
              className={classnames(
                "relative rounded overflow-hidden w-full mb-sm",
                {
                  "hidden md:block": hideOnMobile,
                },
              )}
            >
              {" "}
              {/* Photo if platinum and photo present */}
              {tierValue <= 100 && !!photoUrl && (
                <Image
                  src={photoUrl}
                  sizes={coverPhotoSizes}
                  alt={`${name}-cover-photo`}
                  className="h-32"
                  imageClass="object-cover"
                  disableLazyLoad={disableLazyLoad}
                  data-testid="dispensary-card__photo"
                  preload={preload}
                />
              )}
              {/* Logo, absolute if platinum, larger if platinum without a photoUrl */}
              {!compact && (
                <div
                  className={classnames("rounded overflow-hidden bg-white", {
                    "absolute bottom-0 left-0 m-sm md:block":
                      tierValue <= 100 && !!photoUrl,
                    "mx-auto": tierValue <= 100 && !photoUrl,
                  })}
                  style={{ minWidth: logoSize, width: logoSize }}
                >
                  {logoUrl ? (
                    <Image
                      src={logoUrl}
                      sizes={[logoSize]}
                      alt={`${name}-logo`}
                      ratio="square"
                      disableLazyLoad={disableLazyLoad}
                      preload={preload}
                    />
                  ) : (
                    <div className="p-xs">
                      <StorefrontIcon
                        width={`${logoSize - 8}`}
                        height={`${logoSize - 8}`}
                        className="text-grey"
                      />
                    </div>
                  )}
                </div>
              )}
              {/* Med/rec label, added margin if showing a platinum photo */}
              <div className="flex flex-col absolute top-0 right-0">
                {!!dispensaryTypeText && (
                  <div
                    className={classnames(
                      "text-xs font-bold bg-white border border-light-grey rounded px-xs z-[1]",
                      { "m-sm": tierValue <= 100 && !!photoUrl },
                    )}
                    data-testid="dispensary-card__medrec-label"
                  >
                    {dispensaryTypeText}
                  </div>
                )}
              </div>
            </div>
          )}

          {/* Title and ad badge */}
          <div
            className={classnames({ "w-full min-h-12": shouldSetMinHeight })}
          >
            <div className="flex justify-between">
              <span
                className={classnames("font-bold flex", {
                  "text-xs w-full": compact,
                  "w-55": !compact,
                })}
              >
                {mergedTagsAndFlags.includes("leaflyListBadge") && (
                  <AwardBadgeIcon
                    width={20}
                    height={20}
                    className="flex-shrink-0"
                  />
                )}
                <span
                  className={classnames("truncate-lines", {
                    "truncate-2-lines":
                      shouldSetMinHeight || (tierValue <= 400 && !compact),
                  })}
                >
                  {name}
                </span>
              </span>
              {isAd && !compact && (
                <div>
                  <span
                    className={classnames(
                      "text-xs bg-leafly-white rounded p-1 mt-1 self-end text-grey",
                      { "m-sm": tierValue <= 100 && !!photoUrl },
                    )}
                  >
                    ad
                  </span>
                </div>
              )}
            </div>

            <div
              className={classnames(
                "inline-flex whitespace-nowrap flex-col justify-end",
                {
                  hidden: !featureTier,
                  "ml-sm": compact,
                  "ml-xs": !compact,
                },
              )}
            >
              {!!dispensaryTypeText && hideOnMobile && (
                <div
                  className={classnames(
                    "items-center text-xs font-bold bg-white border border-light-grey rounded px-xs white-space-nowrap block md:hidden z-[1]",
                    { "m-sm": tierValue <= 100 && !!photoUrl },
                  )}
                  data-testid="dispensary-card__medrec-label2"
                >
                  {dispensaryTypeText}
                </div>
              )}

              {isAd && hideOnMobile && !compact && (
                <div className="ml-xs flex justify-end block md:hidden">
                  <span className="text-xs bg-leafly-white rounded p-1 mt-1 self-end text-grey">
                    ad
                  </span>
                </div>
              )}
            </div>
          </div>

          {/* Rating */}
          <div
            className={classnames({
              "flex justify-between": compact,
              "min-h-5": shouldSetMinHeight,
            })}
          >
            {reviewCount && reviewCount > 0 ? (
              <StarRating
                rating={rating}
                ratingCount={reviewCount}
                starWidth={13}
                className="mt-xs"
              />
            ) : (
              <></>
            )}
            {isAd && compact && (
              <div className="ml-xs flex justify-end block md:hidden">
                <span className="text-xs bg-leafly-white rounded p-1 mt-1 self-end text-grey">
                  ad
                </span>
              </div>
            )}
          </div>

          {/* Delivery/pickup pills */}
          <div
            className={classnames("flex flex-wrap my-xs", {
              "min-h-6": shouldSetMinHeight,
            })}
          >
            {tierValue <= 400 && (
              <>
                {hasAnyDelivery && !deliveryServiceAreaRanges && (
                  <div className="flex items-center bg-leafly-white rounded px-sm mr-sm">
                    <ScooterIcon width="12" height="12" />
                    <span className="ml-xs text-xs font-bold my-[2px]">
                      Delivery
                    </span>
                  </div>
                )}

                {(pickupEnabled || inStoreCartEnabled) &&
                  (renderReadinessLabel ? (
                    renderReadinessLabel()
                  ) : (
                    <DefaultPickupLabel />
                  ))}
              </>
            )}
          </div>

          {/* Schedule and distance */}
          {/* If compact, create a fixed-height wrapper to show only what fits on one line */}
          <div
            className={classnames("overflow-hidden mt-xs", { "h-5": compact })}
          >
            <div
              className={classnames({
                "flex flex-wrap items-center": compact,
              })}
            >
              {currentStatus && (
                <ScheduleStatus
                  currentStatus={currentStatus}
                  className="min-h-5 flex-shrink-0"
                  preorderConfigured={preorderConfigured}
                  scheduleType={scheduleType}
                  timeZone={timeZone}
                />
              )}

              {fulfillmentText && (
                <div className="flex items-center">
                  {compact && <div className="mx-xs">&bull;</div>}
                  <div
                    className="flex-shrink-0 text-xs"
                    data-testid="dispensary-card__fulfillment"
                  >
                    {fulfillmentText}
                  </div>
                </div>
              )}
            </div>
          </div>
        </Link>

        {/* Delivery Service Area Details */}
        {deliveryServiceAreaRanges && (
          <KeyDeliveryVariables
            className="mt-xs"
            hideDeliveryFee={hideDeliveryFee}
            deliveryServiceAreaRanges={deliveryServiceAreaRanges}
          />
        )}

        {/* Deal or offer display */}
        <div
          className={classnames({
            "min-h-11 mt-2": shouldSetMinHeight && showDeal,
          })}
        >
          {tierValue <= 100 && dealActive && (
            <Link
              href={dealHref}
              onClick={(e) => onClick?.(e)}
              className="block text-xs border-t border-light-grey pt-sm"
            >
              {dealIsOffer ? (
                <OfferLabel label={dealTitle} textClassName="text-default" />
              ) : (
                <div className="flex items-start">
                  <div className="bg-notification rounded text-white font-bold mr-sm px-1 flex-shrink-0">
                    {discountLabel}
                  </div>
                  <span className="font-bold truncate-lines truncate-2-lines">
                    {dealTitle}
                  </span>
                </div>
              )}
            </Link>
          )}
        </div>
      </div>

      {isNewDispensary && <CardRibbon label="new" />}

      {/* CTA accessory tray or default button */}
      {showCTA &&
        (accessoryTray || (
          <div className="w-full mt-lg">
            <Button
              width="full"
              href={
                isFeaturedDispensariesSection
                  ? dispensaryHref
                  : dispensaryMenuHref
              }
              onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>
                ctaOnClick?.(e)
              }
              data-testid="dispensary-card__cta"
            >
              {ctaText}
            </Button>
          </div>
        ))}
    </div>
  );
};

export default DispensaryCard;
